var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "manualDriverRegistration",
        lazy: "",
        "no-fade": "",
        size: "lg",
        title: _vm.$t("modalSignup.title"),
        "hide-footer": "",
      },
      on: { show: _vm.handleOpen },
    },
    [
      _c("ValidationObserver", {
        ref: "observer",
        attrs: { tag: "form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.doCreateContract()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ valid }) {
              return [
                _c(
                  "b-form",
                  [
                    _c(
                      "b-form-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-8 col-sm-12",
                            attrs: {
                              id: "driver-name-gp",
                              label: `${_vm.config.name.label} *`,
                              "label-for": "driver-name",
                              autocomplete: "off",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|signupNameValid",
                                name: _vm.config.name.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "driver-name",
                                            disabled: _vm.block_ui,
                                            required: "",
                                            type: "text",
                                          },
                                          model: {
                                            value: _vm.newDriver.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newDriver,
                                                "name",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "newDriver.name",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-4 col-sm-12",
                            attrs: {
                              id: "driver-document-gp",
                              label: `${_vm.config.document.label} *`,
                              "label-for": "driver-document",
                              autocomplete: "off",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|cpfValid",
                                name: _vm.config.document.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("the-mask", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "driver-document",
                                            type: "text",
                                            disabled: _vm.block_ui,
                                            autocomplete: "off",
                                            required: "",
                                            mask: _vm.config.document.mask,
                                          },
                                          model: {
                                            value: _vm.newDriver.document,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newDriver,
                                                "document",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "newDriver.document",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-form-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-5 col-sm-12",
                            attrs: {
                              id: "driver-email-gp",
                              label: `${_vm.config.email.label} *`,
                              "label-for": "driver-email",
                              autocomplete: "off",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|emailValid",
                                name: _vm.config.email.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "driver-email",
                                            disabled: _vm.block_ui,
                                            autocomplete: "off",
                                            required: "",
                                            type: "email",
                                          },
                                          model: {
                                            value: _vm.newDriver.email,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newDriver,
                                                "email",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "newDriver.email",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              id: "driver-phone-gp",
                              label: `${_vm.config.phone.label} *`,
                              "label-for": "driver-phone",
                              autocomplete: "off",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|signupPhoneValid",
                                name: _vm.config.phone.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("the-mask", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "driver-phone",
                                            type: "tel",
                                            disabled: _vm.block_ui,
                                            autocomplete: "off",
                                            required: "",
                                            mask: _vm.config.phone.mask,
                                          },
                                          model: {
                                            value: _vm.newDriver.phone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newDriver,
                                                "phone",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression: "newDriver.phone",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-4 col-sm-12",
                            attrs: {
                              id: "driver-work-city-gp",
                              label: `${_vm.config.workCity.label} *`,
                              "label-for": "driver-workCity",
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: {
                                id: "driver-workCity",
                                "text-field": _vm.newDriver.workCity,
                                options: _vm.workCityList,
                                disabled: _vm.block_ui,
                              },
                              model: {
                                value: _vm.workCity,
                                callback: function ($$v) {
                                  _vm.workCity = $$v
                                },
                                expression: "workCity",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-form-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              id: "driver-emergency-phone-gp",
                              label: `${_vm.config.emergencyPhone.label} *`,
                              "label-for": "driver-emergency-phone",
                              autocomplete: "off",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|signupPhoneValid",
                                name: _vm.config.emergencyPhone.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("the-mask", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "driver-emergency-phone",
                                            type: "tel",
                                            disabled: _vm.block_ui,
                                            autocomplete: "off",
                                            required: "",
                                            mask: _vm.config.phone.mask,
                                          },
                                          model: {
                                            value: _vm.newDriver.emergencyPhone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newDriver,
                                                "emergencyPhone",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "newDriver.emergencyPhone",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-9 col-sm-12",
                            attrs: {
                              id: "driver-emergency-name-gp",
                              label: `${_vm.config.emergencyName.label} *`,
                              "label-for": "driver-emergency-name",
                              autocomplete: "off",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|signupNameValid",
                                name: _vm.config.emergencyName.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "driver-emergency-name",
                                            disabled: _vm.block_ui,
                                            autocomplete: "off",
                                            required: "",
                                            type: "email",
                                          },
                                          model: {
                                            value: _vm.newDriver.emergencyName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newDriver,
                                                "emergencyName",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "newDriver.emergencyName",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-form-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-3 col-sm-12",
                            attrs: {
                              id: "driver-license-number-gp",
                              label: `${_vm.config.licenseNumber.label} *`,
                              "label-for": "driver-licenseNumber",
                              autocomplete: "off",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required|cnhValid",
                                name: _vm.config.licenseNumber.label,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("the-mask", {
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "driver-licenseNumber",
                                            type: "tel",
                                            disabled: _vm.block_ui,
                                            autocomplete: "off",
                                            required: "",
                                            mask: _vm.config.licenseNumber.mask,
                                          },
                                          model: {
                                            value: _vm.newDriver.licenseNumber,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.newDriver,
                                                "licenseNumber",
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                              )
                                            },
                                            expression:
                                              "newDriver.licenseNumber",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                        _vm.isPoa
                          ? _c(
                              "b-form-group",
                              {
                                staticClass: "col-md-3 col-sm-12",
                                attrs: {
                                  id: "driver-license-state-gp",
                                  label: `${_vm.config.licenseState.label} *`,
                                  "label-for": "driver-license-state",
                                },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: "signupLicenseStateValid",
                                    name: _vm.config.licenseState.label,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("b-form-select", {
                                              attrs: {
                                                id: "driver-license-state",
                                                options:
                                                  _vm.brazilianStatesList,
                                                disabled: _vm.block_ui,
                                              },
                                              model: {
                                                value: _vm.licenseState,
                                                callback: function ($$v) {
                                                  _vm.licenseState = $$v
                                                },
                                                expression: "licenseState",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "error" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(errors[0]) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "b-form-group",
                          {
                            staticClass: "col-md-4 col-sm-12",
                            attrs: {
                              id: "driver-kyc-gp",
                              label: `${_vm.config.kyc.label} *`,
                              "label-for": "driver-kyc",
                            },
                          },
                          [
                            _c("ValidationProvider", {
                              attrs: {
                                rules: "required",
                                name: _vm.config.kyc.name,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function ({ errors, valid }) {
                                      return [
                                        _c("b-form-checkbox-group", {
                                          attrs: {
                                            id: "driver-kyc",
                                            options: _vm.kycCarUse.items,
                                            name: "flavour-1",
                                            disabled: _vm.block_ui,
                                          },
                                          model: {
                                            value: _vm.kycCarUse.selectedValue,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.kycCarUse,
                                                "selectedValue",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "kycCarUse.selectedValue",
                                          },
                                        }),
                                        _c("span", { staticClass: "error" }, [
                                          _vm._v(" " + _vm._s(errors[0]) + " "),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.isPoa && _vm.licenseState === "RS"
                      ? _c(
                          "b-form-row",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "col-md-3 col-sm-12",
                                attrs: {
                                  id: "driver-fiscal-number-gp",
                                  label: `${_vm.config.fiscalNumber.label} *`,
                                  "label-for": "driver-fiscalNumber",
                                  autocomplete: "off",
                                },
                              },
                              [
                                _c("ValidationProvider", {
                                  attrs: {
                                    rules: "required",
                                    name: _vm.config.fiscalNumber.label,
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("b-form-input", {
                                              staticClass: "form-control",
                                              attrs: {
                                                id: "driver-fiscalNumber",
                                                disabled: _vm.block_ui,
                                                autocomplete: "off",
                                                required: "",
                                                type: "text",
                                              },
                                              model: {
                                                value:
                                                  _vm.newDriver.fiscalNumber,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.newDriver,
                                                    "fiscalNumber",
                                                    typeof $$v === "string"
                                                      ? $$v.trim()
                                                      : $$v
                                                  )
                                                },
                                                expression:
                                                  "newDriver.fiscalNumber",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "error" },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(errors[0]) + " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("b-col", { staticClass: "pl-0", attrs: { cols: "8" } }, [
                      _c("h5", { staticClass: "mt-1 mb-3 ml-0" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("drivers.texts.addressTitle")) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "b-form-row",
                      { staticClass: "mb-2" },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "col-md-12 mb-2" },
                          [
                            _c("kovi-address", {
                              attrs: {
                                "google-input": _vm.configAddress.googleInput,
                                "address-name": _vm.configAddress.addressName,
                                "address-number":
                                  _vm.configAddress.addressNumber,
                                "address-no-number":
                                  _vm.configAddress.addressNoNumber,
                                "address-complement":
                                  _vm.configAddress.addressComplement,
                                "address-neighborhood":
                                  _vm.configAddress.addressNeighborhood,
                                "address-city": _vm.configAddress.addressCity,
                                "address-state": _vm.configAddress.addressState,
                                "address-country":
                                  _vm.configAddress.addressCountry,
                                "address-postal-code":
                                  _vm.configAddress.addressPostalCode,
                                "address-lat-lng":
                                  _vm.configAddress.addressLatLng,
                                "garage-address-name":
                                  _vm.configAddress.garageAddressName,
                                "garage-address-number":
                                  _vm.configAddress.garageAddressNumber,
                                "garage-address-complement":
                                  _vm.configAddress.garageAddressComplement,
                                "garage-address-neighborhood":
                                  _vm.configAddress.garageAddressNeighborhood,
                                "garage-address-city":
                                  _vm.configAddress.garageAddressCity,
                                "garage-address-state":
                                  _vm.configAddress.garageAddressState,
                                "garage-address-country":
                                  _vm.configAddress.garageAddressCountry,
                                "garage-address-postal-code":
                                  _vm.configAddress.garageAddressPostalCode,
                                "message-required-address-fields":
                                  _vm.configAddress
                                    .messageRequiredAddressFields,
                                "message-required-garage-fields":
                                  _vm.configAddress.messageRequiredGarageFields,
                                "message-invalid-address":
                                  _vm.configAddress.messageInvalidAddress,
                                "show-garage-address": true,
                              },
                              on: {
                                "new-address": function ($event) {
                                  return _vm.changeAddress($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    !_vm.hasErrors ? _c("hr") : _vm._e(),
                    _vm.hasErrors
                      ? _c(
                          "b-col",
                          { staticClass: "pl-0 error", attrs: { cols: "8" } },
                          [
                            _c("h5", { staticClass: "mt-1 mb-3 ml-0" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("modalSignup.errors.title")) +
                                  " "
                              ),
                            ]),
                            _vm._l(_vm.errors, function (error, index) {
                              return _c(
                                "p",
                                { key: index, staticClass: "mt-1 mb-2 ml-0" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(`modalSignup.errors.${error}`)
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "b-form-row",
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "text-right mt-1 mb-2",
                            attrs: { cols: "12" },
                          },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled:
                                    !_vm.isValidToCreate || _vm.block_ui,
                                  variant: "success",
                                  title: "$t('modalSignup.buttons.save')",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.doCreateContract.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("modalSignup.buttons.save")) +
                                    " "
                                ),
                                _vm.saving
                                  ? _c("b-spinner", {
                                      staticClass: "ml-1",
                                      staticStyle: {
                                        width: "1rem",
                                        height: "1rem",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }