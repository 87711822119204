var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-driver-list" },
    [
      _c(
        "custom-data-table",
        {
          attrs: {
            fields: _vm.fields,
            filters: _vm.filters,
            query: _vm.driver_list_query,
            "fixed-filters": _vm.fixed_filters,
            "query-node": "drivers",
          },
          on: { "vuetable:row-clicked": _vm.$_goTo },
          scopedSlots: _vm._u([
            {
              key: "cpf",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("format_document")(props.rowData.cpf)) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "name",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(" " + _vm._s(props.rowData.name || "Anônimo") + " "),
                ])
              },
            },
            {
              key: "license_points",
              fn: function (props) {
                return _c("span", {}, [
                  props.rowData.license_status !== "CHECKING"
                    ? _c("span", [
                        _vm._v(_vm._s(props.rowData.license_points || "0")),
                      ])
                    : _c("span", [_vm._v("Verificando")]),
                ])
              },
            },
            {
              key: "renting",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("booking_status")(props.rowData.booking)) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "created_at",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("moment")(props.rowData.created_at, "DD/MM/YYYY")
                      ) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "updated_at",
              fn: function (props) {
                return _c("span", {}, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("moment")(
                          props.rowData.updated_at,
                          "DD/MM/YYYY HH:mm"
                        )
                      ) +
                      " "
                  ),
                ])
              },
            },
            {
              key: "status",
              fn: function (props) {
                return _c(
                  "span",
                  {},
                  [
                    _c(
                      "b-badge",
                      {
                        attrs: {
                          variant: _vm._f("status_variant")(
                            props.rowData.status
                          ),
                        },
                      },
                      [_vm._v(" " + _vm._s(props.rowData.status) + " ")]
                    ),
                  ],
                  1
                )
              },
            },
            {
              key: "edit",
              fn: function (props) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { title: "Editar motorista", variant: "link" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.$_goTo(props.rowData, "edit")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.isAclBtCreateDriver
            ? _c(
                "span",
                { attrs: { slot: "custom_button" }, slot: "custom_button" },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("label", { staticStyle: { color: "transparent" } }, [
                        _vm._v("Search"),
                      ]),
                      _c(
                        "b-button",
                        {
                          staticClass: "custom-data-table__search-btn",
                          attrs: { type: "submit", variant: "info", block: "" },
                          on: { click: _vm.openCreateDriverModal },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("modalSignup.buttons.openModal")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("modal-manual-driver-registration", {
        attrs: { "driver-id": _vm.current_item.id },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }