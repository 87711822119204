var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.drivers
    ? _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "pb-4" },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _c("h2", [
                  _vm._v(
                    " " + _vm._s(this.$route.meta.title || "Motoristas") + " "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm.drivers.items
            ? _c("table-driver-list", { attrs: { fields: _vm.fields_to_show } })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }