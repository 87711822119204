<template>
  <div class="table-driver-list">
    <custom-data-table :fields="fields" :filters="filters" :query="driver_list_query"
                       :fixed-filters="fixed_filters" query-node="drivers" @vuetable:row-clicked="$_goTo"
    >
      <span slot="custom_button" v-if="isAclBtCreateDriver">
        <b-form-group>
          <label style="color: transparent">Search</label>
          <b-button
            type="submit"
            variant="info"
            class="custom-data-table__search-btn"
            block
            @click="openCreateDriverModal"
          >
            {{ $t('modalSignup.buttons.openModal') }}
          </b-button>
        </b-form-group>
      </span>

      <span slot="cpf" slot-scope="props"> {{ props.rowData.cpf | format_document }} </span>
      <span slot="name" slot-scope="props"> {{ props.rowData.name || 'Anônimo' }} </span>
      <span slot="license_points" slot-scope="props">
        <span v-if="props.rowData.license_status !== 'CHECKING'">{{ props.rowData.license_points || '0' }}</span>
        <span v-else>Verificando</span>
      </span>
      <span slot="renting" slot-scope="props"> {{ props.rowData.booking | booking_status }} </span>
      <span slot="created_at" slot-scope="props"> {{ props.rowData.created_at | moment('DD/MM/YYYY') }} </span>
      <span slot="updated_at" slot-scope="props"> {{ props.rowData.updated_at | moment('DD/MM/YYYY HH:mm') }} </span>
      <span slot="status" slot-scope="props">
        <b-badge :variant="props.rowData.status | status_variant">
          {{ props.rowData.status }}
        </b-badge>
      </span>
      <template slot="edit" slot-scope="props">
        <b-button title="Editar motorista" variant="link" @click.prevent="$_goTo(props.rowData, 'edit')">
          <i class="fa fa-edit" />
        </b-button>
      </template>
    </custom-data-table>
    <modal-manual-driver-registration :driver-id="current_item.id" />
  </div>
</template>

<style lang="scss">

</style>

<script>
import {
  leadStatus
} from '@utils/driver';
import CustomDataTable from '@components/CustomDataTable';
import DRIVER_LIST from '@graphql/driver/queries/list.gql';
import modalManualDriverRegistration from '@components/modals/driver/manual-driver-registration';

export default {
  components: {
    CustomDataTable,
    modalManualDriverRegistration
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current_item: {
        id: '0'
      },
      driver_list_query: DRIVER_LIST,
    };
  },
  computed: {
    isAclBtCreateDriver () {
      return this.$store.getters['user/groups'].includes('ACL:BUTTON:CREATE:DRIVER')
    },
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: '',
            placeholder: 'Buscar por nome, CPF, e-mail ou telefone',
          },
          graphqlQuery(value) {
            return {
              $or: [{
                  name: {
                    $like: `%${value}%`,
                  },
                },
                {
                  cpf: {
                    $like: `%${value}%`,
                  },
                },
                {
                  email: {
                    $like: `%${value}%`,
                  },
                },
                {
                  phone_number: {
                    $like: `%${value}%`,
                  },
                },
              ],
            }
          },
        }
      };
    },
    fixed_filters() {
      return {
        status: this.$route.meta.status,
      };
    },
  },
  methods: {
    $_goTo(_item = null, _action = null) {
      if (_item.data) {
        _item = _item.data;
      }
      this.current_item = _item;
      if (_item === null) {
        this.$root.$emit('bv::show::modal', 'modalDriver');
      } else {
        // by actions
        if (_action === 'edit') {
          if (leadStatus.includes(_item.status)) {
            this.$router.push({
              path: `/drivers/profile/${_item.id}/lead-edit`,
              query: {
                redir: this.$route.path
              },
            });
          } else {
            this.$router.push({
              path: `/drivers/profile/${_item.id}/edit`,
            });
          }
        }
        // by status
        else if (leadStatus.includes(_item.status)) {
          this.$router.push({
            path: `/drivers/profile/${_item.id}/lead-edit`,
            query: {
              redir: this.$route.path
            },
          });
        } else if (['PENDING_DOCS', 'PENDING_CAR'].includes(_item.status)) {
          this.$router.push({
            name: 'SignupFlowDetail',
            params: {
              id: _item.id
            },
            query: {
              redir: this.$route.path
            },
          });
        } else if (['PENDING_BOOKING'].includes(_item.status)) {
          this.$root.$emit('bv::show::modal', 'driverPendingBookingInfo');
        } else if (['PENDING_PAYMENT'].includes(_item.status)) {
          this.$root.$emit('bv::show::modal', 'driverPaymentInfo');
        } else {
          this.$router.push({
            path: `/drivers/profile/${_item.id}`
          });
        }
      }
    },
    openCreateDriverModal () {
      this.$root.$emit('bv::show::modal', 'manualDriverRegistration');
    }
  },
};
</script>
