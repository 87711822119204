<script>
import TableDriverList from '@components/table-driver-list';

export default {
  name: 'DriverListByStatus',
  components: {
    TableDriverList
  },
  props: {
    fieldsAllow: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data: () => ({
    fields: [{
        key: 'cpf',
        label: 'CPF',
        sortField: 'cpf'
      },
      {
        key: 'name',
        label: 'Nome',
        sortField: 'name'
      },
      {
        key: 'total_fines',
        label: 'Multas',
      },
      {
        key: 'license_points',
        label: 'Pontos CNH',
      },
      {
        key: 'renting',
        label: 'Alugando',
      },
      {
        key: 'created_at',
        label: 'Data registro',
        sortField: 'created_at'
      },
      {
        key: 'updated_at',
        label: 'Data atualização',
      },
      {
        key: 'status',
        label: 'Etapa',
      },
      {
        key: 'edit',
        label: 'Edit',
      },
    ],
    pagination: {
      current: 1,
      total: 10,
      order: 'DESC',
    },
  }),
  computed: {
    drivers() {
      return this.$store.getters['driver/list'];
    },
    fields_to_show() {
      let fieldsAllow = this.fieldsAllow.length ?
        this.fieldsAllow :
        this.$route.meta.fields ?
        this.$route.meta.fields :
        this.fields;

      fieldsAllow = fieldsAllow.map((field) => {
        if (field.key) {
          return field.key;
        }
        return field;
      });

      const fields = this.fields
        .filter(field => fieldsAllow.includes(field.key))
        .map(field => {
          const item = {
            name: field.key,
            title: field.label
          }

          if (field.sortField) {
            item.sortField = field.sortField
          }

          return item
        });

      return fields;
    },
  },
  created() {
    this.$store.dispatch('driver/clearDriversList');
  },
  mounted() {
    this.$store.dispatch('driver/list', {
      status: this.$route.meta.status,
      page: this.pagination.current - 1,
      limit: this.pagination.total,
      order: this.pagination.order,
    });
  },
  methods: {
    changePagination(val) {
      this.$store.dispatch('driver/list', {
        status: this.$route.meta.status,
        page: val - 1,
        limit: this.pagination.total,
        order: this.pagination.order,
      });
    },
    handleOrderBy(order) {
      this.pagination.order = order;
      this.$store.dispatch('driver/list', {
        status: this.$route.meta.status,
        page: this.pagination.current - 1,
        limit: this.pagination.total,
        order: this.pagination.order,
      });
    },
    goTo(_item = null, _action = null) {
      if (_item === null) {
        this.$root.$emit('bv::show::modal', 'modalDriver');
      } else if (_action === 'edit') {
        this.$router.push({
          path: `/drivers/${_item.id}/edit`
        });
      } else {
        this.$router.push({
          path: `/drivers/${_item.id}`
        });
      }
    },
  },
};
</script>

<template>
  <div v-if="drivers">
    <b-row class="pb-4">
      <b-col cols="12">
        <h2>
          {{ this.$route.meta.title || 'Motoristas' }}
        </h2>
      </b-col>
    </b-row>
    <table-driver-list v-if="drivers.items" :fields="fields_to_show" />
  </div>
</template>
