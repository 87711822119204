import { render, staticRenderFns } from "./manual-driver-registration.vue?vue&type=template&id=579ea5b6&"
import script from "./manual-driver-registration.vue?vue&type=script&lang=js&"
export * from "./manual-driver-registration.vue?vue&type=script&lang=js&"
import style0 from "./manual-driver-registration.vue?vue&type=style&index=0&id=579ea5b6&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/seed/source/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('579ea5b6')) {
      api.createRecord('579ea5b6', component.options)
    } else {
      api.reload('579ea5b6', component.options)
    }
    module.hot.accept("./manual-driver-registration.vue?vue&type=template&id=579ea5b6&", function () {
      api.rerender('579ea5b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/modals/driver/manual-driver-registration.vue"
export default component.exports